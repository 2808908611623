import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { toast, Toaster } from 'react-hot-toast';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';
import moment from 'moment/moment';

import { _balanceTopUp, _getDashboardChart, _getDashboardTodaySent, _ordersGet, _tariffPlansGet } from '../../services/apiConnections.service';

import { ModalDialog } from '../elements/ModalDialog.component';
import { TariffPlanTile } from '../elements/TariffPlanTile.component';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
);

export const Dashboard = () => {

    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [showModalTopUp, setShowModalTopUp] = useState(false);
    const [showModalBuyTariff, setShowModalBuyTariff] = useState(false);
    const [showModalTariffPlans, setShowModalTariffPlans] = useState(false);
    const [balance, setBalance] = useState(null);
    const [tariffs, setTariffs] = useState([]);
    const [noTariffs, setNoTariffs] = useState(false);
    const [orders, setOrders] = useState([]);
    const [noOrders, setNoOrders] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [noChartData, setNoChartData] = useState(false);
    const [chartLabels, setChartLabels] = useState([]);
    const [successfulChart, setSuccessfulChart] = useState([]);
    const [totalChart, setTotalChart] = useState([]);
    const [todaySent, setTodaySent] = useState(0);

    const [topUpForm, setTopUpForm] = useState({
        topUpBy: 10
    });
    const topUpByValues = [10, 20, 30];

    let user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('token'));
        }
        setBalance(user.balance);
        if (token) getTodaySent();
        if (token && !tariffs.length && !noTariffs)
            getTariffPlans();
        if (token && !orders.length && !noOrders)
            getOrders();
        if (token && !chartData.length && !noChartData)
            getChartData();
    }, [token, user, tariffs, noTariffs, orders, noOrders, chartData, noChartData]);

    const getTariffPlans = async () => {
        let result = await _tariffPlansGet(token);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setNoTariffs(!result.tariff_packages.length);
            setTariffs(result.tariff_packages);
        }
        setError(result.error);
    };

    const getOrders = async () => {
        let result = await _ordersGet(token);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setNoOrders(!result.orders.length);
            setOrders(result.orders);
        }
        setError(result.error);
    };

    const handleTopUpRadioChange = (e) => {
        setTopUpForm({
            ...topUpForm,
            [e.target.name]: Number(e.target.value)
        });
    };

    const handleInfoUpdate = (tariffName) => {
        setShowModalBuyTariff(false);
        toast.success(`Congratulations, the tariff ${tariffName} has been purchased`);
        getOrders();
    };

    const handleTopUp = async (amount) => {
        let result = await _balanceTopUp(token, amount);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            window.location.href = result.url;
        }
        setError(result.error);
        setShowModalTopUp(false);
    };

    const getChartData = async () => {
        let result = await _getDashboardChart(token);
        if (result.error) {
            console.log("--error: ", result.error);
        } else {
            setNoChartData(!result.stats.length);
            setChartData(result.stats);
            parseChartData(result.stats);
        }
        setError(result.error);
    };

    const getTodaySent = async () => {
        let result = await _getDashboardTodaySent(token);
        if (result.error) {
            console.log("--error: ", result.error);
        } else {
            setTodaySent(result.sent);
        }
        setError(result.error);
    };

    const parseChartData = (data) => {
        let trueLabels = [];
        for (let i = -1; i < 23; i++) {
            let now = new Date(new Date().setMinutes(0)).setSeconds(0);
            trueLabels.unshift((new Date(now).setHours(new Date(now).getHours() - i)));
        }
        let labels = trueLabels.map((e) => {
            return moment(new Date(e)).format('MM-DD H:mm');
        });
        let successful = [], total = [];
        for (let i = 0; i < 24; i++) {
            // successful.push(0);
            // total.push(0);
            // mock
            total.push(Math.floor(Math.random() * 10));
            successful.push(Math.floor(Math.random() * total[i]));
        }
        for (let i = 0; i < data.length; i++) {
            let time = Date.parse(data[i].time) + new Date().getTimezoneOffset() * 60 * 1000;
            for (let j = 0; j < trueLabels.length; j++) {
                if (new Date(trueLabels[j]).getHours() == new Date(time).getHours() &&
                    new Date(trueLabels[j]).getDate() == new Date(time).getDate()) {
                    // successful[j] = data[i].successful;
                    // total[j] = data[i].total;
                }
            }
        }
        setChartLabels(labels);
        setSuccessfulChart(successful);
        setTotalChart(total);
    };

    const getMaxValue = () => {
        let max = 0;
        for (let i = 0; i < 24; i++) {
            if (max < successfulChart[i]) max = successfulChart[i];
            if (max < totalChart[i]) max = totalChart[i];
        }
        if (max < 10) {
            max = 10;
        } else {
            max = null;
        }
        return max;
    };

    const options = {
        responsive: true,
        layout: {
            padding: 20
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            datalabels: {
                align: "top",
                anchor: "center",
                offset: 0,
                display: 'auto'
            },
            legend: {
                display: false
            },
        },
        scales: {
            x: {
                ticks: {
                    align: "center",
                    autoSkip: false,
                    maxRotation: 0,
                    callback: function (value, index, ticks) {
                        return index % 4 === 0 ? this.getLabelForValue(value) : '';
                    }
                },
                grid: {
                    display: false
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                min: 0,
                max: getMaxValue(),
                ticks: {
                    callback: function (value, index, ticks) {
                        return index % 2 === 0 ? this.getLabelForValue(value) : '';
                    }
                }
            }
        },
    };

    const data = {
        labels: chartLabels,
        datasets: [
            {
                label: 'Sent SMS',
                data: totalChart,
                borderColor: 'rgb(30, 86, 164)',
                backgroundColor: 'rgba(22, 103, 242, .5)',
                yAxisID: 'y',
            },
            {
                label: 'Successfully Sent SMS',
                data: successfulChart,
                borderColor: 'rgb(117, 177, 121)',
                backgroundColor: 'rgba(135, 217, 140, 0.5)',
                yAxisID: 'y',
            },
        ],
    };

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    return (
        <div className='content wideContent'>
            <div className='pageTitle'>
                <span>Dashboard</span>
            </div>
            {error && <p className='msgBlock errorMsg'>{error}</p>}
            <div className='dashboardBlock'>
                <div className='dashboardTop'>
                    <div className='dashboardTopLeft'>
                        <div className='dashboardTopBlock'>
                            <span className='dashboardTopBold'>Total SMS: <span>{todaySent}</span></span>
                        </div>
                        <div className='dashboardTopBlock mobileBlockCopy'>
                            <span className='dashboardTopBold'>Account Balance: <span className='balance'>{balance}</span></span>
                        </div>
                    </div>
                    <div className='dashboardTopRight'>
                        <div className='dashboardTopBlock'>
                            <button
                                className='tariffPlansBtn'
                                onClick={() => {
                                    if (noOrders) return;
                                    setShowModalTariffPlans(true);
                                }}>Tariff Plan: <span className='marginR10'>{orders.length}</span></button>
                            <button
                                className='mainBtn btn mainBtnNewDesign'
                                onClick={() => setShowModalBuyTariff(true)}>Buy</button>
                        </div>
                        <div className='dashboardTopBlock'>
                            <span className='dashboardTopBold desctopBlockCopy'>Account Balance: <span className='balance'>{balance}</span></span>
                            <button
                                className='mainBtn btn'
                                onClick={() => setShowModalTopUp(true)}>Top Up</button>
                        </div>
                    </div>
                </div>
                <div className='dashboardMain'>
                    <div className='diagram'>
                        <div className='diagramTitle'><span>Amount of sent &amp; successfully sent SMS for the last 24 hours</span></div>
                        <ul className='chartLegend'>
                            {data.datasets.map((e, index) =>
                                <li key={index}><div style={{ backgroundColor: e.backgroundColor }}></div><span>{e.label}</span></li>
                            )}
                        </ul>
                        <div className='diagramBody'>
                            <Line
                                options={options}
                                data={data} />
                        </div>
                    </div>
                </div>
            </div>
            <ModalDialog
                show={showModalTopUp}
                onHide={() => setShowModalTopUp(false)}
                onAccept={() => handleTopUp(+topUpForm.topUpBy)}
                title="Balance Top Up"
                acceptBtnText="Top Up">
                <Form className='centeredContent'>
                    <span className='subtitle'>Top Up By:</span>
                    {topUpByValues.map((value) => (
                        <Form.Check
                            type='radio'
                            name='topUpBy'
                            id={`topUpBy${value}`}
                            key={`topUpBy${value}`}
                            value={value}
                            label={`${value}$`}
                            checked={topUpForm.topUpBy === value}
                            onChange={handleTopUpRadioChange} />
                    ))}
                </Form>
            </ModalDialog>
            <Modal
                className='tariffPlansModal'
                show={showModalTariffPlans}
                onHide={() => setShowModalTariffPlans(false)}
                backdrop={true}
                keyboard={false}
                centered>
                <div className="carouselParent">
                    <Carousel
                        swipeable={true}
                        draggable={false}
                        responsive={responsive}
                        infinite={false}
                        showDots={true}
                        keyBoardControl={true}
                        transitionDuration={500}
                        dotListClass="carousel-dot-list-style"
                        containerClass="carousel-container"
                        itemClass="carousel-item-padding-40-px"
                    >
                        {orders.length > 0 &&
                            orders.map((tariff) => {
                                return (
                                    <TariffPlanTile
                                        key={tariff.ID}
                                        tariff={tariff}
                                        bought />
                                )
                            })
                        }
                    </Carousel>
                </div>
            </Modal>
            <Modal
                className='tariffPlansModal'
                show={showModalBuyTariff}
                onHide={() => setShowModalBuyTariff(false)}
                backdrop={true}
                keyboard={false}
                centered>
                {noTariffs &&
                    <div className='emptyDialog'>No tariff plans are available</div>
                }
                <div className="carouselParent">
                    {tariffs.length > 0 &&
                        <Carousel
                            swipeable={true}
                            draggable={false}
                            responsive={responsive}
                            infinite={false}
                            showDots={true}
                            keyBoardControl={true}
                            transitionDuration={500}
                            dotListClass="carousel-dot-list-style"
                            containerClass="carousel-container"
                            itemClass="carousel-item-padding-40-px"
                        >
                            {tariffs.map((tariff) => {
                                return (
                                    <TariffPlanTile
                                        key={tariff.ID}
                                        tariff={tariff}
                                        token={token}
                                        onBuy={handleInfoUpdate} />
                                )
                            })}
                        </Carousel>
                    }
                </div>
            </Modal>
            <Toaster />
        </div>
    );
}